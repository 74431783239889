import { ChevronLeft, ChevronRight } from "lucide-react";

export function CustomArrows({
  leftClick,
  rightClick,
  className,
  id,
}: {
  leftClick?: () => void;
  rightClick?: () => void;
  className?: string;
  id?: number;
}) {
  return (
    <div className={className}>
      <button onClick={leftClick} className={`arrow-left-${id} `}>
        <CustomArrow
          svg={<ChevronLeft />}
          className="bg-Secondary-Dark-Blue-db-50 text-Secondary-Dark-Blue-db-500"
        />
      </button>
      <button onClick={rightClick} className={`arrow-right-${id}  `}>
        <CustomArrow
          svg={<ChevronRight />}
          className="bg-Secondary-Dark-Blue-db-500 text-white"
        />
      </button>
    </div>
  );
}

export default function CustomArrow({
  className,
  svg,
  onClick,
}: {
  className?: string;
  svg: JSX.Element;
  onClick?: () => void;
}) {
  return (
    <div
      onClick={onClick}
      className={`flex size-9 min-h-9 min-w-9 cursor-pointer items-center justify-center rounded-full rtl:rotate-180 ${className}`}
    >
      {svg}
    </div>
  );
}
